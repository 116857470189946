import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../../pages/components/layout";
import "../../css/index.css";
import Scroll from "../../images/sketches/scroll.svg";
import CrossShip from "../../images/sketches/cross-ship.svg";
import Port from "../../images/sketches/port.svg";
import Slide from "react-reveal/Slide";

export default function AikenheadTranscript() {

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  return (
    <Layout pageTitle="Coffee House Lane Transcript">
    <div className="lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Coffee House Lane Transcript</title>
      <meta name="description" content="Coffee House Lane Transcript. A location on the digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Coffee House Lane Transcript" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/aikenheadtranscript" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

      <div className="w-full bg-waterford-ocean h-24"></div>

      <section className="location-hero py-16">
          <h1 className="font-lower p-6 text-white">
            <span className="font-heading text-xl">
              <Trans>Coffee House Lane</Trans>
            </span>
            <br />
            <span className="font-bold text-4xl"><Trans>CoffeeHouseLaneSubtitle</Trans></span>
          </h1>
        <div>
          <div>
           
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-3xl ml-6 lg:mx-auto uppercase"><Trans>Narrator</Trans></h3>
              </div>
              <div className="w-5/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  <Trans>AikenheadTranscript1</Trans>
                </p>
              </div>
              <div className=" py-6 ">
                <Slide up>
                  <img
                    className="w-full "
                    src={Port}
                    alt="Port"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="w-5/6 mx-auto font-lower">
                <h3 className="text-3xl lg:mx-auto uppercase"><Trans>John Aikenhead</Trans></h3>
              </div>
              <div className="w-5/6 mx-auto py-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>AikenheadTranscript2</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide left>
                  <img
                    className="w-full col-span-2"
                    src={CrossShip}
                    alt="CrossShip"
                  />
                  <div className="w-full"></div>
                </Slide>
              </div>
            </div>

            <div className="text-waterford-cyan w-6/6 py-6">
              <div className="font-lower">
                <h3 className="text-3xl w-5/6 mx-auto uppercase"><Trans>Narrator</Trans></h3>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>AikenheadTranscript3</Trans>
                </p>
              </div>
            </div>

            <div className="text-waterford-red w-6/6 py-6">
              <div className="font-lower">
                <h3 className="text-3xl w-5/6 mx-auto uppercase"><Trans>John Aikenhead</Trans></h3>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>AikenheadTranscript4</Trans>
                </p>
              </div>
            </div>

            <div className="mr-0 md:mr-auto py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img
                    className="w-full col-span-2"
                    src={Scroll}
                    alt="Scroll"
                  />
                </Slide>
              </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="font-lower">
                <h3 className="text-3xl w-5/6 mx-auto uppercase"><Trans>Narrator</Trans></h3>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>AikenheadTranscript5</Trans>
                </p>
              </div>
            </div>

          </div>
        </div>

      </section>
    </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
